import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import kebabCase from "lodash.kebabcase";
import Layout from "../layout";
import SEO from "../components/SEO";
import config from "../../blogposts/data/SiteConfig";
import ViewCounter from "../components/ViewCounter";

export default class TagsPage extends Component {
  render() {
    const { group } = this.props.data.allMarkdownRemark;

    return (
      <Layout>
        <SEO />
        <Helmet title={`Tags – ${config.siteTitle}`} />
        <span style={{ display: "none" }}>
          <ViewCounter id="tags" />
        </span>
        <div className="container">
          <h1>Tags</h1>
          <div className="tag-container">
            {group.map((tag, index) => (
              <Link
                key={`${tag.fieldValue}${index}`}
                to={`/tags/${kebabCase(tag.fieldValue)}`}
              >
                <span key={tag.fieldValue}>
                  {tag.fieldValue}{" "}
                  <strong className="count">{tag.totalCount}</strong>
                </span>
              </Link>
            ))}
          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query TagsQuery {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
